<template>
    <div>
        <iq-card>
            <template>
                <!-- <b-row> -->
                    <item-view :prop-data="itemsToView"/>
                <!-- </b-row> -->
            </template>
        </iq-card>
    </div>
</template>
<script>
import ItemView from '@/components/structure/itemView'
import { numberFormatBn } from '@/Utils/fliter'
export default {
    name: 'Show',
    props: ['item'],
    components: { ItemView },
    data () {
        return {
            smartCardApplication: {},
            showData: {
                official: {},
                personal: {},
                address: {},
                land: {},
                application: {}
            }
        }
    },
    computed: {
        isBn () {
            return this.$i18n.locale === 'bn'
        },
        itemsToView () {
            const personalFormatted = [
                {
                    title1: this.$t('cotton_ginner_grower.grower'),
                    value1: this.isBn ? this.item.name_bn : this.item.name,
                    title2: this.$t('globalTrans.father_name'),
                    value2: this.isBn ? this.item.father_name_bn : this.item.father_name
                },
                {
                    title1: this.$t('sidebar.warehouseSubNav.fiscal_year'),
                    value1: this.isBn ? this.item.fiscal_year_bn : this.item.fiscal_year_en,
                    title2: this.$t('globalTrans.date'),
                    value2: this.$options.filters.dateFormat(this.item.achievement_date)
                },
                {
                    title1: this.$t('cotton_ginner_grower.applicantId'),
                    value1: this.$n(this.item.applicant_id),
                    title2: this.$t('globalTrans.region'),
                    value2: this.isBn ? this.item.region_bn : this.item.region_en
                },
                {
                    title1: this.$t('cotton_config.zone_name'),
                    value1: this.isBn ? this.item.zone_bn : this.item.zone_en,
                    title2: this.$t('seedsSeeds.unitName'),
                    value2: this.isBn ? this.item.unit_bn : this.item.unit_en
                },
                {
                    title1: this.$t('globalTrans.district'),
                    value1: this.isBn ? this.item.district_bn : this.item.district_en,
                    title2: this.$t('globalTrans.upazila'),
                    value2: this.isBn ? this.item.upazila_bn : this.item.upazila_en
                },
                {
                    title1: this.$t('globalUserData.mobile_no'),
                    value1: this.numberFormatBn(this.item.mobile_no),
                    title2: this.$t('irrigation_config.address'),
                    value2: this.isBn ? this.item.address_bn : this.item.address
                },
                {
                    title1: this.$t('cotton_ginner_grower.cottonName'),
                    value1: this.isBn ? this.item.cotton_name_bn : this.item.cotton_name_en,
                    title2: this.$t('cotton_ginner_grower.cottonVariety'),
                    value2: this.isBn ? this.item.cotton_variety_bn : this.item.cotton_variety_en
                },
                {
                    title1: this.$t('pump_install.quantity'),
                    value1: this.$n(this.item.quantity),
                    title2: this.$t('cotton_ginner_grower.season'),
                    value2: this.isBn ? this.item.season_bn : this.item.season_en
                },
                {
                    title1: this.$t('cotton_ginner_grower.remarks'),
                    value1: this.isBn ? this.item.remarks_bn : this.item.remarks,
                    colSpan: {
                        title1: 1,
                        value1: 3
                    }
                }
            ]
            return {
                title: '',
                data:
                    [
                        {
                            title: this.$t('ginnerGrower.proAchevementInfo'),
                            type: 'table',
                            data: personalFormatted
                        }
                    ]
            }
        }
    },
    methods: {
        numberFormatBn (number) {
            return numberFormatBn(number, this, { useGrouping: false })
        },
        viewItems () {
            return this.itemsToView
        }
    }
}
</script>
