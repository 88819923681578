<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('cotton_ginner_grower.productionAchievement') + ' ' + $t('globalTrans.search') }} </h4>
            </template>
            <template v-slot:body>
                <form-search :data="searchAttributes" @change="setData" @returned-data="searchData"  :selectedData="selectedData" :key="selectedData"/>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('cotton_ginner_grower.productionAchievement') + ' ' + $t('globalTrans.list') }} </h4>
                    </template>
                    <template v-slot:headerAction>
                        <b-button class="btn_add_new" v-b-modal.modal-4 @click="resetId">
                            <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12">
                                    <div class="table-responsive">
                                        <b-table bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                        <template v-slot:cell(index)="data">
                                            {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template #cell(mobile_no)="data">
                                            {{ numberFormatBn(data.value) }}
                                        </template>
                                        <template #cell(pro_achi_quantity)="data">
                                            {{ $n(data.value) }}
                                        </template>
                                        <template v-slot:cell(quantity)="data">
                                            {{ $n(data.item.quantity) }}
                                        </template>
                                        <template #cell(status)="data">
                                            <span v-if="data.value == 1 " class="badge badge-success"><i class="fas fa-check"></i> {{ $t('cotton_ginner_grower.notClosed') }}</span>
                                            <span v-else class="badge badge-danger"><i class="far fa-pause-circle"></i> {{ $t('cotton_ginner_grower.closed') }}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <a href="javascript:" class="btn_table_action table_action_view" title="View" @click="view(data.item)"><i class="far fa-eye"></i></a>
                                            <a href="javascript:" class="btn_table_action table_action_edit" title="Ginner/Grower Form Modify" v-if="data.item.status == 1" v-b-modal.modal-4 @click="edit(data.item)"><i class="ri-ball-pen-fill"></i></a>
                                            <a href="javascript:" class="btn_table_action table_action_status" title="Status" variant="iq-bg-success border" size="sm" v-if="data.item.status != 2" @click="close(data.item)"><i class="fas fa-check"></i></a>
                                        </template>
                                    </b-table>
                                    </div>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="loadData()"
                                    />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>

        <b-modal id="modal-4" size="xl" :title="formTitle" :hide-footer=true :ok-title="$t('globalTrans.close')">
            <FormV :item="currentItem" @submitted="loadData()"/>
        </b-modal>
        <b-modal id="view-modal" size="lg" :title="$t('cotton_ginner_grower.productionAchievement')" :hide-footer=true>
            <Show :item="currentItem"/>
        </b-modal>
    </b-container>
</template>
<script>
import FormV from './Form'
import RestApi, { agriMarketingServiceBaseUrl } from '@/config/api_config'
import { paList, growerList, paClose } from '../../api/routes'
import { helpers } from '@/mixins/helper-functions'
import FormSearch from '@/components/structure/form/search/FormSearch'
import Show from './Show'
import MasterList from '@/mixins/modal-base-master-list'
import { numberFormatBn } from '@/Utils/fliter'
import { getCottonMarketUserInfo, getUnitAssignedParams } from '@/Utils/data-filtering-by-priviledge'

export default {
    name: 'ListView',
    mixins: [MasterList],
    components: {
        FormV, FormSearch, Show
    },
    data () {
        return {
            search: {
                region_id: '',
                zone_id: '',
                unit_id: '',
                district_id: '',
                upazilla_id: '',
                grower_id: '',
                type: ''
            },
            load: false,
            defaultNull: '',
            currentItem: null,
            upazilaList: [],
            zoneList: [],
            unitList: [],
            growerList: [],
            reloadComponent: 1,
            growerListLoading: 1,
            selectedData: {},
            districtList: []
        }
    },
    computed: {
        cottonMarketUserInfo () {
            return getCottonMarketUserInfo()
        },
        fiscalYearList () {
            const fiscalyearData = this.$store.state.commonObj.fiscalYearList
            return fiscalyearData.sort((a, b) => b.sorting_order - a.sorting_order)
        },
        regionList () {
            return this.cottonMarketUserInfo.regionList.filter(item => item.status === 1)
        },
        // districtList () {
        //     return this.$store.state.commonObj.districtList.filter(item => item.status === 0)
        // },
        isBn () {
            return this.$i18n.locale === 'bn'
        },
        orgList () {
            return this.$store.state.commonObj.organizationProfileList
        },
        currentLocale () {
            return this.$i18n.locale
        },
        formTitle () {
            return !this.currentItem ? this.$t('cotton_ginner_grower.productionAchievement') + ' ' + this.$t('globalTrans.entry') : this.$t('cotton_ginner_grower.form') + ' ' + this.$t('globalTrans.modify')
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
                { label: this.$t('cotton_ginner_grower.zone'), class: 'text-center' },
                { label: this.$t('sidebar.warehouseSubNav.fiscal_year'), class: 'text-center' },
                { label: this.$t('globalTrans.district'), class: 'text-center' },
                { label: this.$t('globalTrans.upazila'), class: 'text-center' },
                { label: this.$t('seedsSeeds.unitName'), class: 'text-center' },
                { label: this.$t('cotton_ginner_grower.growers_name'), class: 'text-center' },
                { label: this.$t('globalUserData.mobile_no'), class: 'text-center' },
                { label: this.$t('cotton_ginner_grower.cottonName'), class: 'text-center' },
                { label: this.$t('pump_install.quantity'), class: 'text-center' },
                { label: this.$t('globalTrans.status'), class: 'text-center' },
                { label: this.$t('globalTrans.action'), class: 'text-center w-20' }
            ]

            var keys = [
                { key: 'index' },
                { key: 'zone_en' },
                { key: 'fiscal_year_en' },
                { key: 'district_en' },
                { key: 'upazila_en' },
                { key: 'unit_en' },
                { key: 'name' },
                { key: 'mobile_no' },
                { key: 'cotton_name_en' },
                { key: 'quantity' },
                { key: 'status' },
                { key: 'action' }
            ]

            if (this.$i18n.locale === 'bn') {
                keys[1] = { key: 'zone_bn' }
                keys[2] = { key: 'fiscal_year_bn' }
                keys[3] = { key: 'district_bn' }
                keys[4] = { key: 'upazila_bn' }
                keys[5] = { key: 'unit_bn' }
                keys[6] = { key: 'name_bn' }
                keys[8] = { key: 'cotton_name_bn' }
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        },
        searchAttributes () {
            return {
                buttonText: this.$t('globalTrans.search'),
                data: [
                    {
                        name: 'region_id',
                        type: 'Select',
                        label: 'globalTrans.region',
                        options: this.regionList,
                        selectTitle: this.$t('globalTrans.select'),
                        readOnly: this.isOfficeUser,
                        selectOptionReadOnly: true
                    },
                    {
                        name: 'zone_id',
                        type: 'Select',
                        label: 'cotton_ginner_grower.zone',
                        options: this.zoneList,
                        selectTitle: this.$t('globalTrans.select'),
                        readOnly: this.isOfficeUser,
                        selectOptionReadOnly: true
                    },
                    {
                        name: 'district_id',
                        type: 'Select',
                        label: 'globalTrans.district',
                        options: this.districtList,
                        selectTitle: this.$t('globalTrans.select'),
                        readOnly: this.isOfficeUser,
                        selectOptionReadOnly: true
                    },
                    {
                        name: 'upazilla_id',
                        type: 'Select',
                        label: 'globalTrans.upazila',
                        options: this.upazilaList,
                        selectTitle: this.$t('globalTrans.select'),
                        readOnly: this.isOfficeUser,
                        selectOptionReadOnly: true
                    },
                    {
                        name: 'unit_id',
                        type: 'Select',
                        label: 'irrigation_config.unit_namel',
                        options: helpers.filterListLang(this.unitList, 'text', 'text_bn', 'value'),
                        selectTitle: this.$t('globalTrans.select'),
                        readOnly: this.isOfficeUser,
                        selectOptionReadOnly: true
                    },
                    {
                        name: 'grower_id',
                        type: 'Select',
                        label: 'cotton_ginner_grower.grower',
                        options: this.growerList,
                        selectTitle: this.$t('globalTrans.select')
                    },
                    {
                        name: 'fiscal_year_id',
                        type: 'Select',
                        label: 'sidebar.warehouseSubNav.fiscal_year',
                        options: helpers.filterListLang(this.fiscalYearList, 'text_en'),
                        selectTitle: this.$t('globalTrans.select')
                    }
                ]
            }
        },
        isOfficeUser () {
        //   return !(this.$store.state.Auth.activeRoleId === 1 || this.$store.state.Auth.authUser.is_org_admin)
          return false
        }
    },
    watch: {
        'search.region_id': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.zoneList = this.setZone(newVal)
            }
        },
        'search.zone_id': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.districtList = this.getDistrictList(newVal)
            }
        },
        'search.unit_id': async function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.growerList = await this.setGrower(newVal)
            }
        },
        'search.district_id': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.upazilaList = this.getUpazilaList(newVal)
            }
        },
        'search.upazilla_id': function (newVal, oldVal) {
            if (newVal) {
                this.unitList = this.setUnit(newVal)
            } else {
                this.unitList = []
            }
        },
        currentLocale: function (newVal, oldVal) {
            if (newVal) {
                this.zoneList = this.setZone(this.search.region_id)
                this.unitList = this.setUnit(this.search.upazilla_id)
                this.upazilaList = this.getUpazilaList(this.search.district_id)
                this.growerList = this.growerList.map(item => {
                return Object.assign({}, item, { text: newVal === 'en' ? item.text_en : item.text_bn })
            })
            }
        },
        cottonMarketUserInfo: function () {
            this.setUserData()
        }
    },
    created () {
        this.setUserData()
        if (this.isOfficeUser) {
          const authOfficeDetail = this.$store.state.Auth.authUser.office_detail
          const unit = this.$store.state.agriMarketing.commonObj.unitList.find(obj => obj.district_id === authOfficeDetail.district_id)
          if (unit !== undefined) {
            this.search.region_id = unit.region_id
            this.search.zone_id = unit.zone_id
            this.search.unit_id = unit.value
            this.search.district_id = unit.district_id
            this.search.upazilla_id = unit.upazilla_id
            this.search.fiscal_year_id = unit.fiscal_year_id

            this.selectedData = Object.assign({}, this.selectedData, {
              region_id: unit.region_id,
              zone_id: unit.zone_id,
              unit_id: unit.value,
              district_id: unit.district_id,
              upazilla_id: unit.upazilla_id,
              fiscal_year_id: unit.fiscal_year_id
            })
          }
        }
        this.loadData()
    },
    methods: {
        close (item) {
            this.load = true
            helpers.swalInit().then(async (result) => {
                if (result.isConfirmed) {
                    const response = await RestApi.deleteData(agriMarketingServiceBaseUrl, paClose + '/' + item.id)
                    if (response.success) {
                        this.loadData()
                    }
                    this.load = false
                }
            })
        },
        async setGrower (unitId) {
            this.growerListLoading = true
            let list = []
            const result = await RestApi.getData(agriMarketingServiceBaseUrl, growerList, { unit_id: unitId })
            if (result.success) {
                const data = result.data
                if (data.length > 0) {
                    this.$store.dispatch('agriMarketingCottonGinnerGrower/setGrowerDropdownList', data)
                    list = data.map(el => {
                        el.text = this.isBn ? el.name_bn : el.name
                        el.text_en = el.name
                        el.text_bn = el.name_bn
                        el.value = el.id
                        return el
                    })
                }
                this.growerListLoading = false
            } else {
                this.growerListLoading = false
            }
            return list
        },
        setZone (regionId) {
            // return this.$store.state.agriMarketing.commonObj.zoneList.filter(el => el.region_id === regionId)
            return this.cottonMarketUserInfo.zoneList.filter(el => el.region_id === regionId)
        },
        setUnit (upazilaId) {
            // return this.$store.state.agriMarketing.commonObj.unitList.filter(el => el.zone_id === zoneId)
            return this.cottonMarketUserInfo.unitList.filter(item => item.status === 1 && item.upazilla_id === upazilaId)
        },
        edit (item) {
            this.currentItem = item
        },
        numberFormatBn (number) {
            return numberFormatBn(number, this, { useGrouping: false })
        },
        setData (data) {
            Object.assign(this.search, data)
        },
        getDistrictList (id) {
            const obj = this.cottonMarketUserInfo.zoneList.find(item => item.value === parseInt(id))
            const districtIdArr = obj.district_ids ? JSON.parse(obj.district_ids) : []
            return this.cottonMarketUserInfo.districtList.filter(item => districtIdArr.includes(parseInt(item.value)))
        },
        getUpazilaList (districtId = null) {
            // const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
            const upazilaList = this.cottonMarketUserInfo.upazilaList.filter(item => item.status === 0)
            if (districtId) {
                return upazilaList.filter(upazila => upazila.district_id === districtId)
            }
            return upazilaList
        },
        view (item) {
            this.currentItem = item
            this.$bvModal.show('view-modal')
        },
        resetId () {
            this.currentItem = null
        },
        async searchData (payload) {
            this.search = payload
            await this.loadData()
        },
        loadData () {
            const unitAssignedParams = getUnitAssignedParams()
            const params = Object.assign({}, this.search, unitAssignedParams, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(agriMarketingServiceBaseUrl, paList, params).then((response) => {
                if (response.success) {
                    if (response.data.data.length > 0) {
                        response.data.data.map(item => {
                            const district = this.$store.state.commonObj.districtList.find(el => el.value === item.district_id)
                            if (typeof district !== 'undefined') {
                                item.district_en = district.text_en
                                item.district_bn = district.text_bn
                            }
                            const upazila = this.getUpazilaList().find(el => el.value === item.upazilla_id)
                            if (typeof upazila !== 'undefined') {
                                item.upazila_en = upazila.text_en
                                item.upazila_bn = upazila.text_bn
                            }
                            const region = this.regionList.find(el => el.value === item.region_id)
                            if (typeof region !== 'undefined') {
                                item.region_en = region.text_en
                                item.region_bn = region.text_bn
                            }
                            const zone = this.$store.state.agriMarketing.commonObj.zoneList.find(el => el.value === item.zone_id)
                            if (typeof zone !== 'undefined') {
                                item.zone_en = zone.text_en
                                item.zone_bn = zone.text_bn
                            }
                            const unit = this.$store.state.agriMarketing.commonObj.unitList.find(el => el.value === item.unit_id)
                            if (typeof unit !== 'undefined') {
                                item.unit_en = unit.text_en
                                item.unit_bn = unit.text_bn
                            }
                            const cotton = this.$store.state.agriMarketing.commonObj.cottonNameList.find(el => el.value === item.cotton_id)
                            if (typeof cotton !== 'undefined') {
                                item.cotton_name_en = cotton.text_en
                                item.cotton_name_bn = cotton.text_bn
                            }
                            const cottonVariety = this.$store.state.agriMarketing.commonObj.cottonVaritiesList.find(el => el.value === item.cotton_variety_id)
                            if (typeof cottonVariety !== 'undefined') {
                                item.cotton_variety_en = cottonVariety.text_en
                                item.cotton_variety_bn = cottonVariety.text_bn
                            }
                            const season = this.$store.state.agriMarketing.commonObj.seasonList.find(el => el.value === item.seasons_id)
                            if (typeof season !== 'undefined') {
                                item.season_en = season.text_en
                                item.season_bn = season.text_bn
                            }
                            const fiscalYar = this.$store.state.commonObj.fiscalYearList.find(el => el.value === item.fiscal_year_id)
                            if (typeof fiscalYar !== 'undefined') {
                                item.fiscal_year_en = fiscalYar.text_en
                                item.fiscal_year_bn = fiscalYar.text_bn
                            }
                            return item
                        })
                    }
                    this.load = false
                    this.paginationData(response.data)
                } else {
                    this.load = false
                }
                this.$store.dispatch('setList', response.data.data)
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            }).catch((error) => {
                if (error.response) {
                    this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
                }
            })
        },
        setUserData () {
            if (this.cottonMarketUserInfo.regionList.length === 1) {
                this.search.region_id = this.cottonMarketUserInfo.regionList[0].value
            }
            if (this.cottonMarketUserInfo.zoneList.length === 1) {
                this.search.zone_id = this.cottonMarketUserInfo.zoneList[0].value
            }
            if (this.cottonMarketUserInfo.districtList.length === 1) {
                this.search.district_id = this.cottonMarketUserInfo.districtList[0].value
            }
            if (this.cottonMarketUserInfo.upazilaList.length === 1) {
                this.search.upazilla_id = this.cottonMarketUserInfo.upazilaList[0].value
            }
            if (this.cottonMarketUserInfo.unitList.length === 1) {
                this.search.unit_id = this.cottonMarketUserInfo.unitList[0].value
            }
            this.selectedData = Object.assign(this.selectedData, this.search)
        }
    }
}
</script>
